<template>
  <div class="section-contact">
    <div class="flex-wrapper section-wrapper">
      <div class="flex-col2">
        <base-business-info />
      </div>
      <div class="flex-col2">
        <base-contact-form />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactUs',
  }
</script>
